"use client";
import { KeyedMutator } from "swr";
import useClientContext from "@/context/ClientContext";
import { useCommonSWR, useNextApiSWR, useSellerSWR } from "@/libs/swr";
import apiAuth from "@/modules/auth/constants/apiAuth";
import apiSeller from "@/modules/seller/constants/apiSeller";
import { IApiKYB, IApiKYBFile, IApiPreferences, IApiProfile } from "@/types/interfaces/account";
import { IApiCustomerAddressesList } from "@/types/interfaces/addresses";
import { IApiAggregatedFavoriteProduct, IApiFavoriteProduct } from "@/types/interfaces/favorites";
import {
  IApiCustomerOrderOfList,
  IApiCustomerOrderRoadmap,
  IApiOrderItemFeedback,
  IApiOrderQuestionReason,
  IApiOrderWarrantyReasons,
  IApiStoreExistedRate,
} from "@/types/interfaces/orders";
import { IApiSocialAccountConnect } from "@/types/interfaces/socials";
import apiProfile from "../constants/apiProfile";
import { prepareQueryParamsToString } from "@/utils/url";
import { IApiFavoriteStore, IApiStore } from "@/types/interfaces/stores";
import { sortByOrdering } from "@/utils/sort";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import { nextApiPublic } from "@/modules/public/constants/apiPublic";

export const useDataProfile = () => {
  const { isAuthorized, isSeller } = useClientContext();

  return useCommonSWR<IApiProfile | null>({
    key: apiAuth.profile(),
    stop: !isAuthorized || isSeller,
  });
};

export const useDataConnectedAccounts = () => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiSocialAccountConnect[]>({
    key: apiProfile.connectedAccounts(),
    stop: !isAuthorized,
    defaultData: [],
  });
};

export const useDataPreferences = () => {
  const { isAuthorized, isSeller } = useClientContext();

  return useCommonSWR<IApiPreferences | null>({
    key: apiAuth.preferences(),
    stop: !isAuthorized || isSeller,
  });
};

export const useDataOrdersAddresses = () => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiCustomerAddressesList>({
    key: apiProfile.ordersAddresses(),
    defaultData: [],
    stop: !isAuthorized,
  });
};

export const useDataCustomerNotificationsSettings = () => {
  const { isAuthorized, isSeller } = useClientContext();
  // TODO not implemented
  return useCommonSWR<any>({
    key: apiProfile.notificationsSettings(),
    defaultData: [],
    stop: !isAuthorized || isSeller,
  });
};

export const useDataFavoritesOfProducts = (
  filterStr = "",
  stop?: boolean
): {
  data: IApiFavoriteProduct[];
  error: any;
  isLoading: boolean;
  mutate: KeyedMutator<IApiFavoriteProduct[]>;
  onNextPage?: () => void;
  ended?: boolean;
} => {
  const { locale, isAuthorized, isSeller } = useClientContext();

  const { data, error, isLoading, mutate, onNextPage, ended } = useCommonSWR<IApiFavoriteProduct[]>({
    key: apiProfile.favoriteProducts(locale, filterStr),
    defaultData: [],
    stop: !isAuthorized || isSeller || stop,
    withPagination: true,
    accumulate: true,
    normalizeData: (data) => data?.products || [],
  });

  return {
    data: data || [],
    error,
    isLoading,
    mutate,
    onNextPage,
    ended,
  };
};

export const useDataFavoriteProductsCategories = (
  filterStr = "",
  stop?: boolean
): {
  data: IApiCategoryMapped[];
  error: any;
  isLoading: boolean;
  mutate: () => void;
} => {
  const { isAuthorized, isSeller, locale } = useClientContext();

  const aggregated = useCommonSWR<IApiAggregatedFavoriteProduct | null>({
    key: apiProfile.favoriteProducts(locale, filterStr),
    defaultData: [],
    stop: !isAuthorized || isSeller || stop,
  });

  const ids = aggregated.data?.categories?.map((i) => i.id) || [];
  const { data, error, isLoading, mutate } = useNextApiSWR<{ data: IApiCategoryMapped[] }>({
    key: `${nextApiPublic.categoriesByIds(ids, locale)}`,
    defaultData: [],
    stop: !isAuthorized || isSeller || stop || !ids.length,
  });

  return {
    data:
      data?.data?.map((i) => ({
        ...i,
        doc_count: aggregated.data?.categories?.find((c) => c.id === i.id)?.doc_count || 0,
      })) || [],
    error,
    isLoading,
    mutate: () => {
      aggregated.mutate();
      mutate();
    },
  };
};

export const useDataFavoritesIdsOfProducts = () => {
  const { isAuthorized, isSeller } = useClientContext();

  return useCommonSWR<number[]>({
    key: apiProfile.favoriteProductsItems(),
    defaultData: [],
    stop: !isAuthorized || isSeller,
  });
};

export const useDataFavoritesOfStores = (
  filterStr = "",
  stop?: boolean
): {
  data: IApiFavoriteStore[];
  error: any;
  isLoading: boolean;
  mutate: KeyedMutator<IApiFavoriteStore[]>;
  onNextPage?: () => void;
  ended?: boolean;
} => {
  const { isAuthorized, isSeller, locale } = useClientContext();

  const { data, error, isLoading, mutate, onNextPage, ended } = useCommonSWR<IApiFavoriteStore[]>({
    key: apiProfile.favoriteStores(locale, filterStr),
    defaultData: [],
    stop: !isAuthorized || isSeller || stop,
    withPagination: true,
    accumulate: true,
  });

  return {
    data: data || [],
    error,
    isLoading,
    mutate,
    onNextPage,
    ended,
  };
};

export const useDataFavoritesIdsOfStores = () => {
  const { isAuthorized, isSeller } = useClientContext();

  return useCommonSWR<number[]>({
    key: apiProfile.favoriteStoresItems(),
    defaultData: [],
    stop: !isAuthorized || isSeller,
  });
};

export const useDataCustomerOrders = (query?: any) => {
  const { isAuthorized, locale } = useClientContext();

  const { period, ...rest } = query;
  let queryParams = rest;
  if (period) {
    const [date_start, date_end] = period.split(",");
    if(date_start) {
      queryParams.date_after = date_start;
    }
    if(date_end) {
      queryParams.date_before = date_end;
    }
  }

  const queryStr = prepareQueryParamsToString({
    ...queryParams,
  });

  // return useCommonSWR<IApiCustomerOrderOfList[]>({
  //   key: apiProfile.orders(queryStr),
  //   defaultData: [],
  //   stop: !isAuthorized,
  // });

  const { data, error, isLoading, mutate, onNextPage, ended } = useCommonSWR<IApiCustomerOrderOfList[]>({
    key: apiProfile.orders(locale, queryStr),
    defaultData: [],
    stop: !isAuthorized,
    withPagination: true,
    accumulate: true,
  });

  return {
    data: data || [],
    error,
    isLoading,
    mutate,
    onNextPage,
    ended,
  };
};

export const useDataCustomerOrder = (orderId: any) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiCustomerOrderOfList | null>({
    key: apiProfile.order(orderId),
    stop: !isAuthorized || !orderId,
  });
};

export const useDataCustomerOrderFeedback = (orderId: any) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiCustomerOrderOfList | null>({
    key: apiProfile.getOrderFeedback(orderId),
    stop: !isAuthorized,
  });
};

export const useDataCustomerOrderItemFeedback = (orderId: number | string, productId: number | string) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiOrderItemFeedback | null>({
    key: apiProfile.getOrderFeedback(orderId),
    stop: !isAuthorized,
    normalizeData: (data: IApiOrderItemFeedback[]) => (data || []).find((i) => i.product_id == productId) || null,
  });
};

export const useDataCustomerOrderRoadmap = (orderId: any) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiCustomerOrderRoadmap[]>({
    key: apiProfile.orderRoadmap(orderId),
    stop: !isAuthorized,
    defaultData: [],
  });
};

export const useDataOrderQuestionReasons = () => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiOrderQuestionReason[]>({
    key: apiProfile.orderQuestionReasons(locale),
    defaultData: [],
    normalizeData: (data) => (data || []).sort(sortByOrdering),
  });
};

export const useDataOrderWarrantyReasons = () => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiOrderWarrantyReasons[]>({
    key: apiProfile.orderWarrantyReasons(locale),
    defaultData: [],
    normalizeData: (data) => (data || []).sort(sortByOrdering),
  });
};

export const useDataKYB = () => {
  const { isAuthorized } = useClientContext();

  return useSellerSWR<IApiKYB | null>({
    key: apiSeller.kyb(),
    stop: !isAuthorized,
    defaultData: {},
  });
};

export const useDataKYBFiles = () => {
  const { isAuthorized } = useClientContext();

  return useSellerSWR<IApiKYBFile[] | null>({
    key: apiSeller.kybFiles(),
    stop: !isAuthorized,
  });
};

export const useDataCustomerStore = (storeSlug: any) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiStore>({
    key: apiProfile.store(storeSlug),
    stop: !isAuthorized,
  });
};

export const useDataCustomerStoreExistedRate = (orderId: any) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiStoreExistedRate | null>({
    key: `${apiProfile.storeExistedRate()}?order_id=${orderId}`,
    stop: !isAuthorized,
    defaultData: null,
  });
};
