const API_ACCOUNT = `/accounts/`;
const API_STORE = `/stores/`;
const API_PRODUCTS = `/products/`;
const API_VARIANTS = `/variants/`;
const API_PROPERTIES = `/properties/`;
const API_ORDERS = `/orders/`;
const API_CATEGORIES = `/categories/`;
const API_FINANCES_INVOICES = `/finance/invoices/`;
const API_FINANCES_PAYMENTS = `/finance/payments/`;
const API_FILES_DOWNLOADS = `/files/downloads/`;
const API_FILES_UPLOADS = `/files/uploads/`;

const apiSeller = {
  profile: () => `${API_ACCOUNT}profile/`,
  kyb: () => `${API_ACCOUNT}kyb/`,
  kybProcessStep: (stepSlug: string) => `${API_ACCOUNT}kyb/process-step/${stepSlug}/`,
  kybVerificationRequest: () => `${API_ACCOUNT}kyb/sent-verification-request/`,
  kybFiles: () => `${API_ACCOUNT}kyb-file/`,
  kybFile: (fileId: any) => `${API_ACCOUNT}kyb-file/${fileId}/`,
  kybUpdateBeneficiary: (beneficiaryId: any) => `${API_ACCOUNT}kyb/update-beneficiary/${beneficiaryId}/`,
  kybDeleteBeneficiary: (beneficiaryId: any) => `${API_ACCOUNT}kyb/delete-beneficiary/${beneficiaryId}/`,

  store: () => `${API_STORE}`,
  storeUpdate: (storeId: string) => `${API_STORE}${storeId}/`,
  switchActivation: (storeId: string) => `${API_STORE}${storeId}/switch-activation/`,
  productCreate: () => `${API_PRODUCTS}`,
  products: (locale: string, filterStr?: string) => `${API_PRODUCTS}?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,
  productsIds: () => `${API_PRODUCTS}short/`,
  product: (locale: string, productId: any) => `${API_PRODUCTS}${productId}/?lang=${locale}`,
  productUpdate: (productId: any) => `${API_PRODUCTS}${productId}/`,
  uniquenessCheckSKU: () => `${API_PRODUCTS}check-sku/`,
  bulkEditProducts: () => `${API_PRODUCTS}bulk-product-edit/`,
  productsAmount: () => `${API_PRODUCTS}total-products-amount/`,
  // Properties
  propertyViewTypes: () => `/property-view-types/`,
  propertyViewType: (typeId: any) => `/property-view-types/${typeId}/`,
  createProperty: () => `${API_PROPERTIES}`,
  properties: (locale: string) => `${API_PROPERTIES}?lang=${locale}`,
  propertiesByIds: (locale: string, ids: number[]) => `/properties-by-id/?lang=${locale}&id__in=${ids.join(",")}`,
  systemProperties: (locale: string, name?: string) =>
    `${API_PROPERTIES}?lang=${locale}&is_custom=0${name ? `&search=${name}` : ""}`,
  customProperties: (locale: string, name?: string) =>
    `${API_PROPERTIES}?lang=${locale}&is_custom=1${name ? `&search=${name}` : ""}`,
  updateProperty: (propertyId: any) => `${API_PROPERTIES}${propertyId}/`,
  updateCustomPropertyValue: (propertyValueId: any) => `/property-values-custom/${propertyValueId}/`,
  property: (locale: string, propertyId: any) => `${API_PROPERTIES}${propertyId}/?lang=${locale}`,
  checkCustomPropertyName: () => `${API_PROPERTIES}check-custom-property-name/`,
  addAdditionalPropertyValues: (propertyId: any) => `${API_PROPERTIES}${propertyId}/add-additional-property-values/`,
  categoryProperties: (locale: string, categoryId: any) =>
    `${API_PRODUCTS}category-properties/${categoryId}/?lang=${locale}`,
  // Product properties
  productPropertyBatchCreate: () => `${API_PRODUCTS}property-batch-create/`,
  createProductProperties: (productId: any) => `${API_PRODUCTS}${productId}/product-properties/`,
  productProperties: (locale: string, productId: any) =>
    `${API_PRODUCTS}${productId}/product-properties/?lang=${locale}`,
  updateProductProperty: (productId: any, protertyId: any) =>
    `${API_PRODUCTS}${productId}/product-properties/${protertyId}/`,
  productProperty: (locale: string, productId: any, protertyId: any) =>
    `${API_PRODUCTS}${productId}/product-properties/${protertyId}/?lang=${locale}`,
  // Product Intents
  productIntentsBatchCreate: () => `${API_PRODUCTS}property-intent/bulk-create/`,
  productIntents: (productId: any) => `${API_PRODUCTS}property-intent/?product=${productId}`,
  productIntent: (intentId: any) => `${API_PRODUCTS}property-intent/${intentId}/`,
  productAddIntent: () => `${API_PRODUCTS}property-intent/`,
  propertyGroups: () => `/property-groups/`,
  propertyGroup: (groupId: any) => `/property-groups/${groupId}/`,
  productTradeCodes: () => `/product-trade-codes/`,
  // Variants
  variants: () => `${API_VARIANTS}`,
  productVariants: (productId: any) => `${API_PRODUCTS}${productId}/product-variants/`,
  productDeleteVariants: (productId: any) => `${API_PRODUCTS}${productId}/delete-product-variants/`,
  variant: (variantId: any) => `${API_VARIANTS}${variantId}/`,
  variantsBatchCreateProperties: () => `${API_VARIANTS}property-batch-create/`,
  variantProperties: (variantId: any) => `${API_VARIANTS}${variantId}/variant-properties/`,
  variantProperty: (variantId: any, variantPropertyId: any) =>
    `${API_VARIANTS}${variantId}/variant-properties/${variantPropertyId}/`,
  orders: () => `${API_ORDERS}`,
  ordersIds: () => `${API_ORDERS}short/`,
  ordersAmount: () => `${API_ORDERS}total-amount/`,
  order: (orderId: any) => `${API_ORDERS}${orderId}/`,
  orderCancelReasons: (locale: string) => `${API_ORDERS}cancel-reason/?lang=${locale}`,
  orderCancel: (orderId: any) => `${API_ORDERS}${orderId}/cancel-order/`,
  orderApprove: (orderId: any) => `${API_ORDERS}${orderId}/approve-order/`,
  ordersStatuses: () => `${API_ORDERS}statuses/`,
  orderQuestionReasons: (locale: string) => `${API_ORDERS}questions/reasons/?lang=${locale}`,
  // TODO
  deliveryProviders: () => `/delivery/provider/`,
  deliveryProvider: (providerId: any) => `/delivery/provider/${providerId}/`,
  deliveryWaybills: () => `/delivery/waybill/`,
  orderWaybill: (orderId: any) => `/delivery/waybill/?order_id=${orderId}`,
  orderRoadmap: (orderId: any) => `${API_ORDERS}roadmap/?order=${orderId}`,
  deliveryWaybill: (waybillId: any) => `/delivery/waybill/${waybillId}/`,
  // TODO
  waybillProviderStatus: (waybillId: any) => `/delivery/waybill/${waybillId}/provider-status/`,
  ordersFeedback: () => `${API_ORDERS}feedback/`,
  ordersFeedbackCSV: () => `${API_ORDERS}feedback/export-csv/`,
  categories: () => `${API_CATEGORIES}`,
  recommendedCategories: () => `/recommended-categories/top-categories/`,
  categoryBreadcrumbs: (id: string) => `${API_CATEGORIES}${id}/breadcrumbs/`,
  category: (slug: string) => `${API_CATEGORIES}${slug}/`,
  categoryById: (categoryId: any) => `${API_CATEGORIES}by-id/${categoryId}/`,
  addresses: () => `${API_STORE}addresses/`,
  address: (addressId: any) => `${API_STORE}addresses/${addressId}/`,
  deliveries: () => `${API_STORE}delivery/`,
  delivery: (deliveryId: any) => `${API_STORE}delivery/${deliveryId}/`,
  brands: () => `/brands/`,
  csvUpload: () => `${API_PRODUCTS}imports/csv-upload/`,
  addCategoryToCategory: () => `/favorites-categories/`,
  deleteCategoryFromFavorite: (categoryId: number) => `/favorites-categories/${categoryId}/`,
  favorites: () => `/favorites-categories/`,
  productDeniedStatuses: () => `${API_PRODUCTS}denied-statuses/`,
  financeInvoices: () => `${API_FINANCES_INVOICES}`,
  financeInvoicesPayProviders: () => `${API_FINANCES_INVOICES}pay-providers/`,
  financeInvoicesStatuses: () => `${API_FINANCES_INVOICES}statuses/`,
  financePayments: () => `${API_FINANCES_PAYMENTS}`,
  financePaymentsPaymentTypes: () => `${API_FINANCES_PAYMENTS}payment-types/`,
  financePaymentsCurrentBalance: () => `${API_FINANCES_PAYMENTS}current-balance/`,
  financePaymentsCurrentPending: () => `${API_FINANCES_PAYMENTS}current-pending/`,
  filesDownloads: () => `${API_FILES_DOWNLOADS}`,
  fileDownloads: (fileId: any) => `${API_FILES_DOWNLOADS}${fileId}/`,
  fileDownloadsDownload: (fileId: any) => `${API_FILES_DOWNLOADS}${fileId}/download/`,
  filesDownloadsRequestExport: () => `${API_FILES_DOWNLOADS}request-export-csv/`,
  filesUploads: () => `${API_FILES_UPLOADS}`,
  fileUploads: (fileId: any) => `${API_FILES_UPLOADS}${fileId}/`,
  fileDataErrors: (fileId: any, locale: string, is_critical: boolean) =>
    `${API_FILES_UPLOADS}${fileId}/errors-data/?lang=${locale}&is_critical=${is_critical ? "1" : "0"}`,
  fileErrorIdentifiers: (fileId: any, is_critical: boolean, errCode: string) =>
    `${API_FILES_UPLOADS}${fileId}/errors-identifiers/${errCode}/?is_critical=${is_critical ? "1" : "0"}`,
  // errors-identifiers
  filesUploadsRequestImportCSV: () => `${API_FILES_UPLOADS}request-import-csv/`,
  filesUploadsRequestImportXML: () => `${API_FILES_UPLOADS}request-import-xml/`,
  csvTemplate: () => `${API_FILES_UPLOADS}template-csv/`,
  xmlTemplate: () => `${API_FILES_UPLOADS}template-xml/`,
  // templateFileUploads: (content: "csv" | "xlsx" | "xml", data: "products") => `/files/uploads/template/?content=${content}&data=${data}`,
  support: () => "/support/",
};

export default apiSeller;
