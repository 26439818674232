import { IApiCustomerNotificationTypes } from "@/types/interfaces/notifications";

const API_ACCOUNT = `/accounts/`;
const API_ORDERS = `/orders/`;
const API_PUBLIC_STORES = `/public/stores/`;
const API_STORES = `/stores/`;

const apiProfile = {
  updateNotificationConfig: (id: number) => `${API_ACCOUNT}notify-config/${id}/`,
  connectedAccounts: () => `${API_ACCOUNT}social/connected_accounts/`,
  repeatOrder: (id: any) => `${API_ORDERS}${id}/repeat-order-to-cart/`,
  orders: (locale: string, queryStr?: string) => `${API_ORDERS}?lang=${locale}${queryStr ? `&${queryStr}`: ''}`,
  orderQuestionReasons: (locale: string) => `${API_ORDERS}questions/reasons/?lang=${locale}`,
  orderWarrantyReasons: (locale: string) => `${API_ORDERS}warranties/reasons/?lang=${locale}`,
  order: (orderId: any) => `${API_ORDERS}${orderId}/`,
  ordersCheckStatus: (ordersIds: (string | number)[]) => `${API_ORDERS}check-status/?orders_ids=${ordersIds.join(',')}`,
  orderRoadmap: (orderId: any) => `${API_ORDERS}roadmap/?order=${orderId}`,
  ordersAddresses: () => `${API_ORDERS}addresses/`,
  ordersAddress: (addressId: any) => `${API_ORDERS}addresses/${addressId}/`,
  notificationsSettings: () => `/notifications/settings/`,
  notificationsUnread: () => `/notifications/unread/`,
  addPavoriteProduct: () => `${API_ACCOUNT}favorite/products/`,
  favoriteProducts: (locale: string, filterStr?: string) => `${API_ACCOUNT}favorite/products/?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,
  favoriteProductsItems: () =>
    `${API_ACCOUNT}favorite/products/favorite-items/`,
  favoriteProduct: (productId: any) =>
    `${API_ACCOUNT}favorite/products/${productId}/`,
  deleteFavoriteProduct: (productId: any) =>
    `${API_ACCOUNT}favorite/products/${productId}/delete_by_product/`,
  addFavoriteStore: () => `${API_ACCOUNT}favorite/stores/`,
  favoriteStores: (locale: string, filterStr?: string) => `${API_ACCOUNT}favorite/stores/?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,
  favoriteStoresItems: () => `${API_ACCOUNT}favorite/stores/favorite-items/`,
  favoriteStore: (storeId: any) => `${API_ACCOUNT}favorite/stores/${storeId}`,
  deleteFavoriteStore: (storeId: any) =>
    `${API_ACCOUNT}favorite/stores/${storeId}/delete_by_store/`,
  orderFeedback: () => `${API_ORDERS}item/feedback/`,
  getOrderFeedback: (orderId: any) => `${API_ORDERS}item/feedback/?order=${orderId}`,
  orderFeedbackDelete: (feedbackId: any) => `${API_ORDERS}item/feedback/${feedbackId}/`,
  orderFeedbackSetLike: (feedbackId: any) => `${API_ORDERS}item/feedback/${feedbackId}/set-like/`,
  orderWarranty: (locale?: string) => `${API_ORDERS}warranties/${locale ? `?lang=${locale}` : ""}`,
  store: (storeSlug: any) => `${API_PUBLIC_STORES}${storeSlug}/`,
  storeRate: () => `${API_STORES}rate/`,
  storeExistedRate: () => `${API_STORES}rate/existed-rate/`,
};

export default apiProfile;
